// Some of this stuff is from sites.udel.edu/cpa

code {
  background: #D9EDF7;
}

.nav-topic {
  padding-left: 10px;
  padding-top: 10px;
  border-bottom: 1px solid gray;
  color: darken(@udel-gray, 10%);
  font-size: larger;
  font-weight: bold;
}

// The card for landing/overview pages:
.eecis-card {
  h3 {
    margin-top: 10px; // normally 20px
  }
}


////////////////////////////////////////////////////////////////////////////////
// Custom Panels
//.panel-research {
//  .panel-variant(@panel-danger-border; @panel-danger-text; @panel-danger-heading-bg; @panel-danger-border);
//}

////////////////////////////////////////////////////////////////////////////////
// Tables
// This is a hack to transfer all the table, etc to be allowed to be set by a
// wrapping div.
// This is since we have no control over the table's class which are generated
// by markdown.
div.eecis-table > table {
  .table();
}
div.eecis-table.table-condensed > table {
  .table-condensed();
}
div.eecis-table.table-bordered > table {
  .table-bordered();
}
div.eecis-table.table-striped > table {
  .table-striped();
}
div.eecis-table.table-hover > table {
  .table-hover();
}
div.eecis-table.table-responsive > table {
  .table-responsive();
}

// We want those defaults for our tables:
table {
  .table();
  .table-hover();
  .table-bordered();
}


////////////////////////////////////////////////////////////////////////////////
// Header

#ud-header {
  width: 100%;
  height: 90px;
  overflow: hidden;
  border-bottom: 2px solid white;
  background: linear-gradient(to bottom, #002663 0%,#00498d 100%);
}

//@media (min-width: @screen-sm-min) {

#ececis-header {
  position: relative;
  // lg: 45%, md: 35%, sm: 0% +margin-left: 10%
  // sm also needs to move #ud-header-identity
  left: 45%;
  margin-left: 100px; // 73px from the logo + 25px space
  margin-top: -45px;
  a {
    color: white;
    font-size: 22px;
    &:hover {
      text-decoration: none;
    }
  }
}

// Image is 147x60 pixels
// Hence the 73px offset
#ud-header-identity {
  position: relative;
  // TODO: Move me around
  // lg: 45%
  left: 45%;
  margin-left: -73px;
}

#ud-seal {
  position: absolute;
  top: 0;
  left: -700px;
  z-index: 1;
}

#ud-logo {
  position: relative;
  top: 21px;
  z-index: 2;
}

////////////////////////////////////////////////////////////////////////////////
// Navigation
.nav-container {
  background-color: white;
  border: 1px solid lightgrey;
  margin: 5px;
}
.nav-justified a:hover {
  border-bottom: 4px solid @udel-gold;
  margin-bottom: -4px;
}

.nav-left li:hover:not(.nav-topic) {
  border-left: 3px solid @udel-blue;
  margin-left: -3px;
}

////////////////////////////////////////////////////////////////////////////////
// Footer
html {
  // necessary for the sticky footer
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 310px;
}
.eecis-footer-container {
  // Removed, somehow messes up firefox:
  //height: 270px; // Needs to be equal to body margin-bottom!!
  position: absolute;
  padding-top: 20px;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  //background: darken(@udel-fade-blue, 10%);
  //background: lighten(#000, 20%);
  //background: darken(@udel-gray-bg, 5%);
  //background: darken(@udel-dark-blue, 0%);
  //background: darken(#528FAC, 1%);
  background: lighten(@udel-bg-blue, 5);
  //border-top: 4px solid @udel-gold;
  //border-top: 3px solid darken(@udel-gray-bg, 30%);
  border-top: 3px solid darken(@udel-gold, 0%);
  // The font color:
  color: darken(#fff, 10%);
  //color: lighten(#000, 10%);
}
.footer-styles {
  font-size: large;
  //font-type: 'Lato', sans-serif;
  a {
    //color: lighten(@udel-bright-blue, -5%);
    color: fadeout(white, 40%);
    &:hover {
      color: fadeout(white, 60%);
      text-decoration: none;
    }
  }
  h4 {
    font-weight: 500;
    color: fadeout(white, 5%);
  }
  .copyright {
    font-size: small;
    color: fadeout(white, 20%);
  }
}
